/*
 * @Author: Aimee~
 * @Date: 2024-03-29 11:26:26
 * @LastEditTime: 2024-05-16 09:02:07
 * @LastEditors: Aimee
 * @FilePath: /Web/src/router/routes/zone.js
 * @Description: 专区
 */
import LayoutView from '@/components/layouts/Layout.vue'

export default [
  {
    path: '/zone',
    component: LayoutView,
    children: [
      {
        path: ':zoneId(\\d+)',
        name: 'Zone',
        meta: {
          title: '专区',
          keepAlive: true
        },
        component: () => import('@/views/mall/zone/index.vue')
      },
      {
        path: '/bigzone',
        name: 'BigZone',
        meta: {
          title: '大客户专区',
          keepAlive: true
        },
        component: () => import('@/views/mall/zone/bigZone.vue')
      }
    ]
  },
  {
    path: '/xinhuabooks',
    component: LayoutView,
    children: [
      {
        path: '',
        name: 'XinhuaBooks',
        meta: {
          title: '新华专区',
          keepAlive: true
        },
        component: () => import('@/views/mall/xinhuabook/index.vue')
      }
    ]
  },
  {
    path: '/party',
    component: LayoutView,
    children: [
      {
        path: '',
        name: 'Party',
        meta: {
          title: '党政读物'
        },
        component: () => import('@/views/mall/party/index.vue')
      }
    ]
  },
  {
    path: '/hotbooks',
    component: LayoutView,
    children: [
      {
        path: '',
        name: 'HotsBooks',
        meta: {
          title: '畅销专区'
        },
        component: () => import('@/views/mall/hots/index.vue')
      }
    ]
  },
  {
    path: '/newbooks',
    component: LayoutView,
    children: [
      {
        path: '',
        name: 'NewsBooks',
        meta: {
          title: '新品专区'
        },
        component: () => import('@/views/mall/news/index.vue')
      }
    ]
  },
  {
    path: '/teaching',
    component: LayoutView,
    children: [
      {
        path: ':categoryId(\\d+)',
        name: 'Teaching',
        meta: {
          title: '教材教辅'
        },
        component: () => import('@/views/mall/teaching/index.vue')
      }
    ]
  },
  {
    path: '/more',
    component: LayoutView,
    children: [
      {
        path: '/more',
        name: 'More',
        meta: {
          title: '更多分类'
        },
        component: () => import('@/views/home/more/index.vue')
      }
    ]
  },
  {
    path: '/kills',
    component: LayoutView,
    children: [
      {
        path: '/kills',
        name: 'Kills',
        meta: {
          title: '更多秒杀'
        },
        component: () => import('@/views/mall/kills/index.vue')
      }
    ]
  }
]
