<template>
  <div>
    <!-- tool -->
    <div class="tool">
      <div class="container tool-flex">
        <div class="tool-user">
          <template v-if="auth.isLogin">
            欢迎使用北国书香E购网<router-link :to="{ name: 'User' }"> {{ auth.info.nickname }}</router-link> <span @click="doLogout">退出</span>
          </template>
          <template v-else>
            欢迎光临北国书香E购网，请<router-link :to="{ name: 'Auth' }">登录</router-link>成为会员
          </template>
        </div>
        <div class="tool-navs">
          <router-link :to="{ name: 'MallCart' }">购物车</router-link>
          <span>|</span>
          <router-link :to="{ name: 'Order', query: {} }">我的订单</router-link>
          <span>|</span>
          <router-link :to="{ name: 'Make' }">我的预约单</router-link>
          <span>|</span>
          <router-link :to="{ name: 'UserFavorite' }">我的收藏</router-link>
<!--          <span>|</span>-->
<!--          <a href="#">大客户专区</a>-->
          <span>|</span>
          <router-link :to="{ name: 'User' }">个人中心</router-link>
        </div>
      </div>
    </div>
    <!-- 导航栏 -->
    <div class="container">
      <div class="header-flex">
        <router-link :to="{ name: 'Home' }">
          <img class="logo" src="@/assets/images/logo.png">
        </router-link>
        <div class="search">
          <input placeholder="搜索关键字" v-model="searchVal" @keydown.enter="onSearch"/>
          <button @click="onSearch()">
            <SearchOutlined/>
          </button>
        </div>
        <div class="btns">
          <router-link :to="{ name: 'MallCart' }">购物车</router-link>
          <router-link :to="{ name: 'Order', query: {} }">我的订单</router-link>
        </div>
      </div>
    </div>
    <!-- 导航栏 -->
    <div class="navs">
      <div class="container navs-flex">
        <router-link :to="{ path: '/' }">全部商品分类<DownOutlined /></router-link>
        <router-link :to="{ path: nav.paths.pc }" v-for="(nav, index) in navs" :key="index">{{nav.title}}</router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useAuthStore } from '@/stores/auth'
  import { useStores } from '@/stores/stores.js'
  import { useRoute } from 'vue-router'
  import { SearchOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
  import { message, Modal, notification } from 'ant-design-vue'
  import { createVNode, ref, watch } from 'vue'
  import router from '@/router/index.js'

  const route     = useRoute()
  const auth      = useAuthStore()
  const searchVal = ref('')
  const navs      = ref([])

  const doLogout = () => {
    Modal.confirm({
      title: '退出登录',
      icon: createVNode(ExclamationCircleOutlined),
      content: '确定要退出登录吗？',
      onOk() {
        auth.logout()
        notification.success({
          message: '退出成功',
          description: '欢迎下次再来',
          duration: 2
        })
      },
      onCancel() { }
    })
  }

  // 搜索
  const onSearch = () => {
    if (!searchVal.value || searchVal.value.length === 0) {
      message.warn('请输入搜索内容')
      return
    }
    router.push({ name: 'Search', query: { keyword: searchVal.value } })
  }

  // 监听vuex状态更新数据
  watch(() => useStores().navs, () => {
    navs.value = useStores().navs
  })

</script>

<style lang="scss" scoped>
  /* tool */
  .tool{
    background: #f6f7f8;
    border-bottom: solid 1px #ddd;
    font-size: 12px;
    &-flex{
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 20px;
    }
    &-user{
      padding: 10px 0;
      color: #555;
      a{ color: $red; font-weight: normal; margin: 0 3px; }
      span{
        cursor: pointer;
        &:hover{ color: $red; }
      }
    }
    &-navs{
      span{ color: #ddd; }
      a{
        color: #555;
        padding: 0 10px;
        line-height: 40px;
        display: inline-block;
        font-weight: normal;
        &:hover{ background: white; color: $red; }
      }
    }
  }
  /* header */
  .header-flex{
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo{ height: 57px; object-fit: cover; width: 216px; }
    .search{
      height: 40px;
      display: flex;
      input{ outline: none; border:solid 2px $red; width: 400px; line-height: 36px; padding: 0 15px; border-right: 0; }
      button{
        background: $red;
        border: none;
        color: white;
        width: 40px;
        height: 40px;
        transition: all .2s;
        font-size: 16px;
        &:hover{ background: $red-weight; }
      }
    }
    .btns{
      background: #f6f7f8;
      line-height: 36px;
      a{
        width: 120px;
        display: inline-block;
        text-align: center;
        &:first-child{
          background: $red;
          color: white;
          border: solid 2px $red;
          font-weight: bold;
          &:hover{ background: $red-weight; border-color: $red-weight; }
        }
        &:last-child{ border: solid 2px #ddd; border-left: 0; box-sizing: border-box; }
      }
    }
  }
  /* navs */
  .navs{
    border-bottom: solid 4px $red;
    &-flex{
      display: flex;
      justify-content: space-between;
      a{
        color: #333;
        line-height: 45px;
        font-size: 15px;
        padding: 0 15px;
        margin-right: 30px;
        &:hover{ color: $red; }
        &:first-child{
          background: $red;
          color: white;
          width: 200px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
</style>
