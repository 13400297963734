import { ref, reactive } from 'vue'
import { defineStore } from 'pinia'
import userApi from '@/apis/user'

export const useAuthStore = defineStore(
  'auth',
  () => {
    const isLogin = ref(false)
    const token = ref('')
    const info = reactive({
      user_id: 0,
      username: '',
      nickname: '',
      avatar: '',
      birthday: '',
      gender: ''
    })
    const inviteCode = ref('')
    const account = reactive({
      balance: '0.00',
      score: '0.00'
    })
    const orderCount = reactive({
      deliverd: 0,
      init: 0,
      paid: 0,
      refund: 0,
      signed: 0
    })
    const login = (tokenString) => {
      token.value = tokenString
      isLogin.value = true

      updateUserInfo()
    }

    const updateUserInfo = () => {
      // 保存登录信息以后，在这里获取登录用户的信息
      userApi
        .userInfo()
        .then((res) => {
          info.user_id = res.info.user_id
          info.username = res.info.username
          info.nickname = res.info.nickname
          info.avatar = res.info.avatar
          info.birthday = res.info.birthday
          info.gender = res.info.gender

          account.balance = res.account.balance
          account.score = res.account.score

          inviteCode.value = res.invite_code
        })
        .catch((err) => {})
    }

    const logout = () => {
      token.value = ''
      isLogin.value = false
      info.user_id = 0
      info.username = ''
      info.nickname = ''
      info.avatar = ''
      info.birthday = ''
      info.gender = ''
      inviteCode.value = ''
    }

    return {
      token,
      isLogin,
      info,
      account,
      inviteCode,
      orderCount,
      updateUserInfo,
      login,
      logout
    }
  },
  {
    persist: {
      paths: ['isLogin', 'token', 'account', 'info', 'orderCount', 'inviteCode']
    }
  }
)
