import LayoutView from '@/components/layouts/Layout.vue'

export default [
  {
    path: '/user',
    component: LayoutView,
    meta: {
      requiresAuth: true,
      keepAlive: true
    },
    children: [
      {
        path: '',
        name: 'User',
        meta: {
          title: '个人中心'
        },
        component: () => import('@/views/user/index.vue')
      },
      {
        path: 'info',
        children: [
          {
            path: '',
            name: 'UserInfo',
            meta: {
              title: '我的资料'
            },
            component: () => import('@/views/user/info/index.vue')
          },
          {
            path: 'avatar',
            name: 'UserInfoAvatar',
            meta: {
              title: '修改头像'
            },
            component: () => import('@/views/user/info/avatar.vue')
          }
        ]
      },
      {
        path: 'histories',
        name: 'UserHistory',
        meta: {
          title: '我的足迹'
        },
        component: () => import('@/views/user/history.vue')
      },
      {
        path: 'addresses',
        children: [
          {
            path: '',
            name: 'UserAddress',
            meta: {
              title: '地址管理'
            },
            component: () => import('@/views/user/address/index.vue')
          },
          {
            path: 'create',
            name: 'UserAddressCreate',
            meta: {
              title: '新增地址'
            },
            component: () => import('@/views/user/address/create.vue')
          },
          {
            path: ':addressId(\\d+)',
            name: 'UserAddressEdit',
            meta: {
              title: '编辑地址'
            },
            component: () => import('@/views/user/address/edit.vue')
          }
        ]
      },
      {
        path: 'business',
        name: 'ShopSettled',
        meta: {
          title: '商家入驻'
        },
        component: () => import('@/views/user/business.vue')
      },
      {
        path: 'kinship',
        children: [
          {
            path: '',
            name: 'Kinship',
            meta: {
              title: '亲情号'
            },
            component: () => import('@/views/user/kinship/index.vue')
          },
          {
            path: 'create',
            name: 'KinshipCreate',
            meta: {
              title: '新增亲情号'
            },
            component: () => import('@/views/user/kinship/create.vue')
          }
        ]
      },
      {
        path: 'invoice',
        children: [
          {
            path: '',
            name: 'UserInvoice',
            meta: {
              title: '我的发票'
            },
            component: () => import('@/views/user/invoice/index.vue')
          },
          {
            path: 'invoice/create',
            name: 'InvoiceCreate',
            meta: {
              title: '新增发票抬头'
            },
            component: () => import('@/views/user/invoice/create.vue')
          },
          {
            path: 'invoice/detail/:id',
            name: 'InvoiceDetail',
            meta: {
              title: '发票详情'
            },
            component: () => import('@/views/user/invoice/detail.vue')
          },
          {
            path: 'invoice/apply/:id/:no',
            name: 'InvoiceApply',
            meta: {
              title: '申请发票'
            },
            component: () => import('@/views/user/invoice/apply.vue')
          }
        ]
      },
      {
        path: 'favorites',
        name: 'UserFavorite',
        meta: {
          title: '我的收藏'
        },
        component: () => import('@/views/user/favorite.vue')
      },
      {
        path: 'notifications',
        name: 'Notification',
        meta: {
          title: '站内信'
        },
        component: () => import('@/views/user/notification/index.vue')
      },
      {
        path: 'coupons',
        name: 'UserCoupons',
        meta: {
          title: '我的优惠券'
        },
        component: () => import('@/views/user/coupon.vue')
      },
      {
        path: 'scores',
        name: 'UserScore',
        meta: {
          title: '我的积分'
        },
        component: () => import('@/views/user/score/index.vue')
      },
      {
        path: 'balances',
        name: 'UserBalance',
        meta: {
          title: '我的余额'
        },
        component: () => import('@/views/user/balance/index.vue')
      },
      {
        path: 'cards',
        name: 'UserCard',
        meta: {
          title: '我的会员卡'
        },
        component: () => import('@/views/user/card/index.vue')
      },
      {
        path: 'safe',
        children: [
          {
            path: '',
            name: 'UserSafe',
            meta: {
              title: '安全中心'
            },
            component: () => import('@/views/user/safe/index.vue')
          },
          {
            path: 'password',
            name: 'UserSafePassword',
            meta: {
              title: '修改支付密码'
            },
            component: () => import('@/views/user/safe/password.vue')
          },
          {
            path: 'payment',
            name: 'UserSafePayment',
            meta: {
              title: '修改支付密码'
            },
            component: () => import('@/views/user/safe/payment.vue')
          },
          {
            path: 'safe/reset/phone',
            name: 'UserSafeResetPhone',
            meta: {
              title: '重置手机号'
            },
            component: () => import('@/views/user/safe/resetphone.vue')
          }
        ]
      }
    ]
  }
]
