
import { defineStore } from 'pinia'
import { ref } from 'vue'
import homeApi from '@/apis/home.js'

export const useStores = defineStore('useHeaderNavs', () => {
  const navs = ref([])
  const categories = ref([])

  homeApi.header().then(res => {
    navs.value = res.navs
    categories.value = res.categories
  })

  return {
    navs,
    categories
  }
})
