/**
 * 预约专区
 */
import LayoutView from '@/components/layouts/Layout.vue'


export default [
  {
    path: '/make',
    component: LayoutView,
    children: [
      {
        path: '/make',
        name: 'Make',
        meta: {
          title: '我的预约单',
          requiresAuth: true
        },
        component: () => import('@/views/make/index.vue')
      },{
        path: '/make/area',
        name: 'MakeArea',
        meta: {
          title: '创建预约单',
          requiresAuth: true
        },
        component: () => import('@/views/make/area.vue')
      },{
        path: '/make/goods/:type',
        name: 'MakeGoods',
        meta: {
          title: '创建预约单',
          requiresAuth: true
        },
        component: () => import('@/views/make/goods.vue')
      },{
        path: '/make/order',
        name: 'MakeOrder',
        meta: {
          title: '确认预约单',
          requiresAuth: true
        },
        component: () => import('@/views/make/order.vue')
      }
    ]
  }
]
