import LayoutView from '@/components/layouts/Layout.vue'

export default [
  {
    path: '/ebook',
    component: LayoutView,
    children: [
      {
        path: ':bookId(\\d+)',
        name: 'EBook',
        meta: {
          title: '电子书详情'
        },
        component: () => import('@/views/mall/goods/ebook.vue')
      }
    ]
  }
]
