import request from '../utils/request'

const search = (keyword) => {
  return request.post(`mall/allrearch`, {keyword})
}

const header = () => {
  return request.get(`mall/pc/header`)
}
// 全局推荐商品
const recommends = (page, limit = 15) => {
  return request.get(`mall/recommend?limit=${limit}&page=${page}`)
}

export default {
  search,
  header,
  recommends
}
