import LayoutView from '@/components/layouts/AuthLayout.vue'

export default [
  {
    path: '/app/privacy',
    name: 'Privacy',
    meta: {
      title: '隐私协议'
    },
    component: () => import('@/views/extend/privacy.vue')
  }
]
