<!--
 * @Author: Aimee~
 * @Date: 2024-05-14 13:55:21
 * @LastEditTime: 2024-05-22 15:19:04
 * @LastEditors: Aimee
 * @FilePath: /Web/src/components/common/EmptyWidget.vue
 * @Description:暂无数据
-->
<template>
  <div class="nullCover" :style="`min-height: ${height};`">
    <a-empty :image="simpleImage" :imageStyle="imageStyle">
      <template #description>
        {{ description }}
      </template>
    </a-empty>
  </div>
</template>

<script setup>
import { Empty } from 'ant-design-vue';
const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
// 空空如也样式
const imageStyle = {
  height: '100px',
  width: '100px'
};

const props = defineProps({
  description: {
    type: String,
    default: () => {
      return '暂无数据'
    }
  },
  height: {
    type: String,
    default: () => {
      return '50vh'
    }
  }
})
</script>
<style scoped lang="scss">
.nullCover {
  min-height: 50vh;
  @extend .flex-row;
}
</style>