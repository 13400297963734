<!--
 * @Author: Aimee~
 * @Date: 2024-04-01 16:03:23
 * @LastEditTime: 2024-05-22 12:01:58
 * @LastEditors: Aimee
 * @FilePath: /Web/src/components/user/Avatar.vue
 * @Description: 自定义头像信息
-->
<template>
  <div>
    <a-avatar :size="size" v-if="avatar == '' || avatar == null"
      :style="`color: #fff; background-color: ${red}; font-size: ${fontSize}px; font-weight: bold;`">
      {{ store.info.nickname.substring(0, 1) }}
    </a-avatar>
    <a-avatar v-else :size="size" :src="avatar" :shape="shape" />
  </div>
</template>

<script setup>
import { red } from '@/assets/bridge.module.scss'
import { useAuthStore } from '@/stores/auth.js'

const store = useAuthStore()
const props = defineProps({
  avatar: {
    type: String,
    default: ''
  },
  size: {
    type: Number,
    default: 80
  },
  fontSize: {
    type: Number,
    default: 30
  },
  shape: {
    type: String,
    default: 'circle'
  }
})
</script>

<style lang="scss" scoped></style>
