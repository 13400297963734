<!--
 * @Author: Aimee~
 * @Date: 2024-03-29 11:26:26
 * @LastEditTime: 2024-04-17 16:28:28
 * @LastEditors: Aimee
 * @FilePath: /Web/src/App.vue
 * @Description: 
-->
<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="includeList">
      <a-config-provider :theme="theme" :locale="zhCN">
        <component :is="Component" />
      </a-config-provider>
    </keep-alive>
  </router-view>
</template>

<script setup>
import { ref, reactive, watch } from 'vue'
import { useRoute } from 'vue-router'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { red } from '@/assets/bridge.module.scss'

dayjs.locale('zh-cn')

const theme = reactive({
  token: {
    colorPrimary: red
  }
})

const includeList = ref(['Home'])
const route = useRoute()

watch(route, (to) => {
  if (to.meta?.keepAlive && includeList.value.indexOf(to.name) === -1) {
    includeList.value.push(to.name)
  }
})
</script>
