/*
 * @Author: Aimee~
 * @Date: 2024-03-29 11:26:26
 * @LastEditTime: 2024-05-09 09:10:50
 * @LastEditors: Aimee
 * @FilePath: /Web/src/router/routes/live.js
 * @Description: 直播组件
 */
import LiveLayout from '@/components/layouts/LiveLayout.vue'
import Layout from '@/components/layouts/Layout.vue'
export default [
  {
    path: '/lives',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Live',
        meta: {
          title: '直播列表',
          keepAlive: true
        },
        component: () => import('@/views/live/index.vue')
      },
      {
        path: ':liveId(\\d+)',
        name: 'LiveShow',
        meta: {
          title: '直播间',
          requiresAuth: true,
          keepAlive: true
        },
        component: () => import('@/views/live/show.vue')
      }
    ]
  }
]
