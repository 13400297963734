/*
 * @Author: Aimee~
 * @Date: 2024-03-29 11:26:26
 * @LastEditTime: 2024-05-13 17:02:27
 * @LastEditors: Aimee
 * @FilePath: /Web/src/apis/request.js
 * @Description: 封装网络请求
 */
import axios from 'axios'
import moment from 'moment'
import { useAuthStore } from '@/stores/auth'
import router from '@/router'

/**
 * 创建一个axios的实例
 */
const request = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 60000
})

/**
 * 配置axios请求拦截器
 */
const axiosConf = (config) => {
  const store = useAuthStore()
  config.headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + store.token,
    'U-Request-Time': moment().format('YYYYMMDDHHmmss')
  }
  return config
}

request.interceptors.request.use(axiosConf, (error) => {
  return Promise.reject(error)
})

request.interceptors.response.use(async (response) => {
  if (response.status === 401 || response.data?.code === 401) {
    const store = useAuthStore()
    store.logout()
    router.push({ name: 'Auth' })
    return Promise.reject(response.data)
  } else if (response.status === 200 && response.data.code === 200) {
    return Promise.resolve(response.data.data)
  } else {
    return Promise.reject(response.data)
  }
})
export default request
