/*
 * @Author: Aimee~
 * @Date: 2024-04-17 09:43:13
 * @LastEditTime: 2024-04-22 17:35:02
 * @LastEditors: Aimee
 * @FilePath: /Web/src/router/routes/order.js
 * @Description: 我的订单
 */
import LayoutView from '@/components/layouts/Layout.vue'

export default [
    {
        path: '/orders',
        component: LayoutView,
        meta: {
            keepAlive: false,
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'Order',
                meta: {
                    title: '我的订单'
                },
                component: () => import('@/views/order/index.vue')
            }, {
                path: 'detail/:orderId',
                name: 'OrderDetail',
                meta: {
                    title: '订单详情'
                },
                component: () => import('@/views/order/detail.vue')
            }, {
                path: 'init',
                name: 'OrderInit',
                meta: {
                    title: '确认订单'
                },
                component: () => import('@/views/order/init.vue')
            },{
                path: 'cart',
                name: 'OrderCart',
                meta: {
                    title: '购物车结算'
                },
                component: () => import('@/views/order/cart.vue')
            }, {
                path: 'pay',
                name: 'OrderPay',
                meta: {
                    title: '收银台'
                },
                component: () => import('@/views/order/pay.vue')
            }, {
                path: 'logistics/:type/:express_id',
                name: 'OrderLogistics',
                meta: {
                    title: '物流信息'
                },
                component: () => import('@/views/order/logistics.vue')
            }, {
                path: 'review',
                name: 'OrderReview',
                meta: {
                    title: '评论'
                },
                component: () => import('@/views/order/review.vue')
            }, {
                path: 'refund',
                name: 'OrderRefund',
                meta: {
                    title: '退货订单'
                },
                component: () => import('@/views/order/refund/index.vue')
            }, {
                path: 'refund/apply/:orderId',
                name: 'OrderRefundDetail',
                meta: {
                    title: '申请售后'
                },
                component: () => import('@/views/order/refund/detail.vue')
            }, {
                path: 'refund/info/:id',
                name: 'OrderRefundInfo',
                meta: { title: '售后详情' },
                component: () => import('@/views/order/refund/info.vue')
            }, {
                path: 'refund/delivered/:id',
                name: 'OrderRefundDelivered',
                meta: { title: '寄回货品' },
                component: () => import('@/views/order/refund/delivered.vue')
            }, {
                path: 'refund/success',
                name: 'OrderRefundSuccess',
                meta: { title: '提交成功' },
                component: () => import('@/views/order/refund/success.vue')
            }
        ]
    }
]
