/*
 * @Author: Aimee~
 * @Date: 2024-04-17 09:43:13
 * @LastEditTime: 2024-04-22 17:35:02
 * @LastEditors: Aimee
 * @FilePath: /Web/src/router/routes/order.js
 * @Description: 我的订单
 */
import LayoutView from '@/components/layouts/Layout.vue'

export default [
  {
    path: '/payment',
    component: LayoutView,
    meta: {
      keepAlive: false,
      requiresAuth: true
    },
    children: [
      {
        path: 'pay',
        name: 'PaymentPay',
        meta: {
          title: '收银台'
        },
        component: () => import('@/views/payment/pay.vue')
      }
    ]
  }
]
