import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'

import LayoutView from '@/components/layouts/Layout.vue'

import auth from './routes/auth'
import user from './routes/user'
import mall from './routes/mall'
import live from './routes/live'
import cms from './routes/cms'
import zone from './routes/zone'
import order from './routes/order'
import ebook from './routes/ebook'
import extend from './routes/extend'
import jd from './routes/jd'
import make from './routes/make'
import payment from './routes/payment'

const routes = [
  {
    path: '/',
    component: LayoutView,
    children: [
      {
        path: '',
        name: 'Home',
        meta: {
          keepAlive: true
        },
        component: () => import('@/views/home/index.vue')
      },
      {
        path: 'search',
        name: 'Search',
        component: () => import('@/views/home/search.vue'),
        meta: {
          title: '搜索结果'
        }
      },
      {
        path: '404',
        name: 'NotFound',
        component: () => import('@/views/404.vue'),
        meta: {
          title: '页面不存在',
          keepAlive: true
        }
      }
    ]
  },
  ...auth,
  ...user,
  ...mall,
  ...live,
  ...cms,
  ...zone,
  ...order,
  ...ebook,
  ...extend,
  ...jd,
  ...make,
  ...payment,
  {
    path: '/:pathMatch(.*)*',
    name: 'Miss',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes
})

function setDocumentTitle(meta) {
  if (meta.title === undefined) {
    return
  }
  document.title = meta?.title + ' 北国书香网'

  const ua = navigator.userAgent
  const regex = /\bMicroMessenger\/([\d.]+)/
  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const i = document.createElement('iframe')
    i.src = '/favicon.ico'
    i.style.display = 'none'
    i.onload = function () {
      setTimeout(function () {
        i.remove()
      }, 9)
    }
    document.body.appendChild(i)
  }
}

router.beforeEach((to, from, next) => {
  const store = useAuthStore()
  typeof to.meta !== 'undefined' && setDocumentTitle(to.meta)

  if (to.name !== 'Auth' && to.meta.requiresAuth && !store.isLogin) {
    next({ name: 'Auth', query: { r: to.path } })
  } else if (store.isLogin && to.name === 'Auth') {
    next({ name: 'User' })
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  if (from.meta?.scrollToTop !== false) {
    window.scrollTo(0, 0)
  }
})

export default router
export { setDocumentTitle }
