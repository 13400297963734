import LayoutView from '@/components/layouts/Layout.vue'

export default [
  {
    path: '/contents',
    meta: {
      keepAlive: true
    },
    component: LayoutView,
    children: [
      {
        path: '',
        name: 'Content',
        meta: {
          title: '资讯'
        },
        component: () => import('@/views/content/index.vue')
      },
      {
        path: ':contentId(\\d+)',
        name: 'ContentShow',
        meta: {
          title: '资讯详情'
        },
        component: () => import('@/views/content/show.vue')
      }
    ]
  },
  {
    path: '/page',
    component: LayoutView,
    children: [
      {
        path: ':type',
        name: 'StaticPage',
        component: () => import('@/views/content/static.vue'),
        meta: {
          title: '',
          keepAlive: true
        }
      }
    ]
  }
]
